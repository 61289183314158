import React from 'react';
import CategoryBlockItem from './CategoryBlockItem';
import Platform from 'react-platform-js';

class CategoryBlock extends React.Component {
	constructor(props) {
		super(props);
		this.popupPackBuyHandler = this.popupPackBuyHandler.bind(this);
	}
	popupPackBuyHandler(on, packInfo) {
		this.props.popupAction(on, packInfo);
	}
	render() {
		return (
			<div className="category-block">
				<p className="category-block_name">{this.props.name}</p>
				<div className="category-block_items-wrapper">
			       {this.props.packs.map((pack, i) => { 
			           if ((Platform.OS == 'Windows' || Platform.OS == 'MacOS' || Platform.OS == 'Android') || pack.base == 1 || pack.base == 2)
			           		return (<CategoryBlockItem key={i} pack={pack} popupAction={this.popupPackBuyHandler} history={this.props.history} />) 
			        })}
		       </div>
			</div>
			);
	}
}

export default CategoryBlock;