import React from 'react';
import {config} from '../config';
import connect from '@vkontakte/vkui-connect';

class PopupCardLearned extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			donotshow: false,
		}
	}

	share = () => {
		connect.send("VKWebAppShowWallPostBox", {"message": "Я выучил слово "+this.props.card.word+" в сервисе по изучению английского языка!", "attachments": "https://vk.com/app6945494_224346383"});
	}

	handleChange = (e) => {
		this.setState({donotshow: !this.state.donotshow});
	}

	handleClick = () => {
		this.props.popupAction(false, this.state.donotshow);
	}

	render() {
		return (
			<div className="popup-overlay">
			    <style dangerouslySetInnerHTML={{__html: `
			      .list-page, detail-age {
			      	overflow: hidden;
			      }
			    `}} />
				<div className="popup">
					<div className="popup_close" onClick={this.handleClick}></div>
					<div className="popup_card_image" style = {{backgroundImage: `url(${config.imageURL+this.props.card.image})`}}></div>
					<p className="popup_card_word">{this.props.card.word}</p>
					<p className="popup_card_word-learned">Слово выучено!</p>
					<p className="popup_card_statistic">Выученных слов из набора: <span>{this.props.pack.cards_learned}  / {this.props.pack.cards_count}</span></p> 
					<p className="popup_card_statistic">Всего выучено слов: <span>{this.props.cardsLearnedTotal} / {this.props.cardsTotal}</span></p>
					<div className="popup_card_button_share" onClick={this.share}>Поделиться <span></span></div>
					<p className="popup_card_button_continue" onClick={this.handleClick}>Продолжить</p>
					<div className="popup_card_hide"><input type="checkbox" defaultChecked={this.state.donotshow} onChange={this.handleChange} /> больше не показывать</div>
				</div>
			</div>
			);
	}
}

export default PopupCardLearned;