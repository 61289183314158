import React from 'react';
import {config} from '../config';

class CardImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		}
	}

	cardSelect = () => {
		this.setState({active: true});
	}
	render() {
		return (
			<div onClick={() => this.props.action(this.props.index)} className={`game_cards_image ${this.props.transparent ? 'transparent' : ''} ${this.props.active ? 'active' : ''} ${this.props.correct ? 'correct' : ''} ${(!this.props.correct && this.props.active) ? 'wrong' : ''}`} style = {{backgroundImage: `url(${config.imageURL+this.props.image})`}}>
				<p className="game_cards_image_word">{this.props.word_russian}</p>
			</div>
			);
	}
}

export default CardImage;