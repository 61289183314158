import React from 'react';
import {config} from '../config';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import Platform from 'react-platform-js';
class CategoryBlockItem extends React.Component {
	constructor(props) {
		super(props);
	}
	gameStart = (e) => {
		if (this.props.pack.base != 1 && !(this.props.pack.base == 2 && Platform.OS == 'iOS') && !this.props.pack.unlocked) {
			//e.preventDefault();
			this.props.popupAction(true, this.props.pack);
		} else {
			this.props.history.push("/game/"+this.props.pack.id);
		}
	}
	declOfNum = (number, titles) => {
		{/*Вспомогательная функция для склонения числительных*/}
	    var cases = [2, 0, 1, 1, 1, 2];  
	    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
	}
	cardCountWord = () => {
		return this.declOfNum(this.props.pack.count, ['карточка', 'карточки', 'карточек']);
	}
	render() {
		return (
			<div onClick={this.gameStart} className="category-block_item" style = {{backgroundImage: `url(${config.imageURL+this.props.pack.image})`}}>
				<p className="category-block_item_name">{this.props.pack.name}</p>
				<p className="category-block_item_count">{this.props.pack.count} {this.cardCountWord()}</p>
				{this.props.pack.base != 1 && !this.props.pack.unlocked && !(this.props.pack.base == 2 && Platform.OS == 'iOS') &&
					<div className="category-block_item_locked"></div>
				}
			</div>
			);
	}
}

export default CategoryBlockItem;