import React from 'react';
import {config} from '../config';
import connect from '@vkontakte/vkui-connect';

class PopupCardLearned extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	share = () => {
		connect.send("VKWebAppShowWallPostBox", {"message": "Я выучил все слова из набора "+this.props.pack.name+" в сервисе по изучению английского языка!", "attachments": "https://vk.com/app6945494_224346383"});
	}

	render() {
		return (
			<div className="popup-overlay">
			    <style dangerouslySetInnerHTML={{__html: `
			      .list-page, detail-age {
			      	overflow: hidden;
			      }
			    `}} />
				<div className="popup">
					<div className="popup_close" onClick={() => this.props.popupAction(false)}></div>
					<div className="popup_card_image" style = {{backgroundImage: `url(${config.imageURL+this.props.pack.image})`}}></div>
					<p className="popup_card_word">Поздравляем!</p>
					<p className="popup_card_word-learned">Ты выучил все слова набора «{this.props.pack.name}»</p>
					<p className="popup_card_statistic">Выученных слов из набора: <span>{this.props.pack.cards_learned}  / {this.props.pack.cards_count}</span></p> 
					<p className="popup_card_statistic">Всего выучено слов: <span>{this.props.cardsLearnedTotal} / {this.props.cardsTotal}</span></p>
					<div className="popup_card_button_share" onClick={this.share}>Поделиться <span></span></div>
					<p className="popup_card_button_continue" onClick={() => this.props.popupAction(false)}>Продолжить</p>
				</div>
			</div>
			);
	}
}

export default PopupCardLearned;