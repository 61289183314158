import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import PageTransition from "react-router-page-transition";
import axios from 'axios';
//import connect from '@vkontakte/vkui-connect-mock';
import connect from '@vkontakte/vkui-connect';
import './App.css';
import {config} from './config';
import Intro from './components/Intro';
import Main from './components/Main';
import Game from './components/Game';
import PopupPackBuy from './components/PopupPackBuy';
import { YMInitializer } from 'react-yandex-metrika';

class App extends Component {
  constructor(props) {
    super(props);
    this.introHandler = this.introHandler.bind(this);{/*https://ourcodeworld.com/articles/read/409/how-to-update-parent-state-from-child-component-in-react*/}
    this.state = {
      loaded: false,
      vkUser: null,
      authorized: false,
      vkParams: '',
      vkSign: '',
      notifications: false,
      animationSpeed: 500,
      donotshow: false,//Скрывать ли уведомления о выученном слове юзеру
    };
  }

  componentDidMount() {
    connect.subscribe((e) => {
      switch (e.detail.type) {
        case 'VKWebAppGetUserInfoResult':
          var self = this;
          this.setState({ vkUser: e.detail.data}, function() {
            axios.get(config.apiURL, {
              params: {
                a: "auth",
                vk_id: self.state.vkUser.id,
                vk_params: self.state.vkParams,
                vk_sign: self.state.vkSign,
              }
            }).then(function(response) {
              if (response.data.registered == true) {
                self.setState({loaded: true, authorized: true, donotshow: response.data.user.donotshow});
                if (response.data.user.notifications != self.state.notifications) {
                  {/*Сообщаем серверу, если человек подписался или отписался вне приложения*/}
                  axios.get(config.apiURL, {
                      params: {
                          a: 'notifications_set',
                          on: self.state.notifications,
                          vk_id: self.state.vkUser.id,
                          vk_params: self.state.vkParams,
                          vk_sign: self.state.vkSign,
                      }
                  }).then(function (response) {
                    if (response.data.hasOwnProperty("ok")) {
                      self.setState({notifications: response.data.notifications});
                    }
                  });
                }
              } else {
                self.setState({loaded: true});
              }
            });
          });
          break;
        case 'VKWebAppAllowNotificationsResult':
          if (e.detail.data.result == true) {
            var self = this;
            axios.get(config.apiURL, {
                params: {
                    a: 'notifications_set',
                    on: 1,
                    vk_id: self.state.vkUser.id,
                    vk_params: self.state.vkParams,
                    vk_sign: self.state.vkSign,
                }
            }).then(function (response) {
              if (response.data.hasOwnProperty("ok")) {
                self.setState({notifications: response.data.notifications});
              }
            });
          }
          break;
        default:
          break;
      }
    });
    {/*Здесь мы собираем параметры запуска чтобы проверить, тот ли это человек, за кого себя выдаёт
    Подробности: https://vk.com/dev/vk_apps_docs2?f=6.1%20%D0%9F%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C%20%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D0%BE%D0%B2%20%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D0%BA%D0%B0*/}
    var query = this.parseQueryString(window.location.search), vk_params = "", vk_sign = "";
    for (var index in query) {
      if (index != "sign") vk_params += "&"+index+"="+query[index];
      else vk_sign = query[index];
    }
    vk_params = vk_params.substring(1);
    {/*Собрали нужную строку, только после этого может начать запрос пользователя
    vk_id, vkParams и vkSign отправляем при каждом запросе на бэк*/}
    this.setState({ vkParams: vk_params, vkSign: vk_sign, notifications: query['vk_are_notifications_enabled'] }, function() {
      connect.send('VKWebAppGetUserInfo', {});
    });
    //Красим статус бар. Не работает с connect-mock, комменти когда тестишь
    connect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#4a76a8"});
  }


  parseQueryString = (string) => {
    {/*Функция для разбора параметрова запуска, подробности выше*/}
    return string.slice(1).split('&')
    .map((queryParam) => {
      let kvp = queryParam.split('=');
      return {key: kvp[0], value: kvp[1]}
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value;
      return query
    }, {})
  }

  introHandler = () => {
    {/*Это функцию мы передаём в компонент Intro, чтобы он мог сообщить о том что юзер прошёл обучение и зарегался*/}
    this.setState({
      'authorized': true,
    });
  }

  render() {
    if (this.state.loaded === false) return (
      <div>Загрузка...</div>
      );
    return (
      <div>
		<YMInitializer accounts={[53823664]} options={{webvisor: true}} version="2" />
        <Route
          render={({ location }) => (
            <PageTransition timeout={500}>{/*Вот эта обёртка для того, чтобы отдельные компоненты в рутинге листались как слайды*/}
              <Switch location={location}>{/*Тут мы ещё делаем руты по условию, чтобы незарегистрированным в базе выдавали по пути / интро, а зарегистрированным главный экран*/}
                { !this.state.authorized && 
                <Route exact
                  path="/"
                  render={(props) => <Intro {...props} vkParams={this.state.vkParams} vkSign={this.state.vkSign} vkUser={this.state.vkUser} action={this.introHandler} />}
                />
                }
                { this.state.authorized && 
                <Route exact
                  path="/"
                  render={(props) => <Main {...props} notifications={this.state.notifications} vkParams={this.state.vkParams} vkSign={this.state.vkSign} vkUser={this.state.vkUser} popupAction={this.popupPackBuyHandler} />}
                />
                }
                <Route
                  path="/game/:packId"
                  render={(props) => <Game {...props} donotshow={this.state.donotshow} notifications={this.state.notifications} vkParams={this.state.vkParams} vkSign={this.state.vkSign} vkUser={this.state.vkUser} />}
                />
              </Switch>
            </PageTransition>
          )}
        />
      </div>
      );
  }
}

export default App;