import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import connect from '@vkontakte/vkui-connect';

class Header extends React.Component {
	constructor(props) {
		super(props);
	}
	notifications = () => {
		connect.send("VKWebAppAllowNotifications", {});
	}
	render() {
		return (
			<div className={`header ${this.props.game ? 'header-game' : ''}`}>
				<div className="header_controls">
					{this.props.game &&
						<Link to="/" className="header_controls_back"></Link>
					}
					{this.props.notifications == 0 &&
						<div onClick={this.notifications} className="header_controls_notify"></div>
					}
				</div>
				<div className="header_profile">
					<div className="header_profile_photo" style = {{backgroundImage: `url(${this.props.vkUser.photo_200})`}}></div>
					<p className="header_profile_name">{this.props.vkUser.first_name} {this.props.vkUser.last_name}</p>
					{this.props.game && this.props.pack !== null &&
						<div>
							<p className="header_profile_word">Выученных слов из категории "{this.props.pack.name}":</p>
							<p className="header_profile_count">{this.props.pack.cards_learned} из {this.props.pack.cards_count}</p>
						</div>
					}
					{!this.props.game &&
						<p className="header_profile_word">Выученных слов: <span>{this.props.cardsLearned}</span></p>
					}
				</div>
			</div>
			);
	}
}

export default Header;