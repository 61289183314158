import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import axios from 'axios';
import {config} from '../config';
import Header from './Header';
import CategoryBlock from './CategoryBlock';
import PopupPackBuy from './PopupPackBuy';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.popupPackBuyHandler = this.popupPackBuyHandler.bind(this);
    this.state = {
      packs: [],
      packsPopular: [],
      packsNew: [],
      popupPackBuy: false,
      packInfo: null,
      cardsLearned: '',
    };
  }

  componentDidMount() {
    var self = this;
    axios.get(config.apiURL, {
        params: {
            a: 'get_packs',
            vk_id: self.props.vkUser.id,
            vk_params: self.props.vkParams,
            vk_sign: self.props.vkSign,
        }
    }).then(function (response) {
      if (!response.data.hasOwnProperty('error')) {
        {/*Помечаем все купленные паки как разблокированные*/}
        for (var i = 0; i < response.data.packs.length; i++) {
          if (response.data.unlimited == 1 || response.data.packs_unlocked.indexOf(response.data.packs[i].id) !== -1) {
            response.data.packs[i].unlocked = true;
          }
        }
        for (var i = 0; i < response.data.packs_popular.length; i++) {
          if (response.data.unlimited == 1 || response.data.packs_unlocked.indexOf(response.data.packs_popular[i].id) !== -1) {
            response.data.packs_popular[i].unlocked = true;
          }
        }
        for (var i = 0; i < response.data.packs_new.length; i++) {
          if (response.data.unlimited == 1 || response.data.packs_unlocked.indexOf(response.data.packs_new[i].id) !== -1) {
            response.data.packs_new[i].unlocked = true;
          }
        }
        self.setState({
          packs: response.data.packs,
          packsPopular: response.data.packs_popular,
          packsNew: response.data.packs_new,
          cardsLearned: response.data.cards_learned,
          packsUnlocked: response.data.packsUnlocked,
          unlimited: response.data.unlimited,
        });
      }
    });
  }

  popupPackBuyHandler(on, packInfo = null, bought = false) {
    document.getElementById("main").scrollTop = 0;
    this.setState({
      'popupPackBuy': on,
      'packInfo': packInfo,
    });
    /*if (bought) {
      for (var i = 0; i < this.state.packs; i++) {
        var pack = this.state.packs[i];
        if (pack.id == packInfo.id) {
          var packs = this.state.packs;
          packs[i].unlocked = true;
          this.setState({packs: })
          break;
        }
      }
    }*/
  }

  render() {
    return (
      <div id="main" className="transition-item list-page">
      	<Header notifications={this.props.notifications} vkUser={this.props.vkUser} game={false} cardsLearned={this.state.cardsLearned} />
        <CategoryBlock name="Популярные категории" packs={this.state.packsPopular} popupAction={this.popupPackBuyHandler} history={this.props.history} />
        <CategoryBlock name="Новые категории" packs={this.state.packsNew} popupAction={this.popupPackBuyHandler} history={this.props.history} />
        <CategoryBlock name="Все категории" packs={this.state.packs} popupAction={this.popupPackBuyHandler} history={this.props.history} />
        { this.state.popupPackBuy &&
          <PopupPackBuy popupAction={this.popupPackBuyHandler} history={this.props.history} pack={this.state.packInfo} vkParams={this.props.vkParams} vkSign={this.props.vkSign} vkUser={this.props.vkUser} />
        }
      </div>
    );
  }
}

export default Main;