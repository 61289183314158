import React from 'react';
import {config} from '../config';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
import base64 from 'base-64';

class PopupPackBuy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	componentDidMount() {
		connect.subscribe((e) => {
			switch (e.detail.type) {
	        case 'VKWebAppOpenPayFormFailed':
	        break;
	        case 'VKWebAppOpenPayFormResult':
	        	var self = this;
				setTimeout(function() {
	        		self.props.history.push("/");
	                }, 1000);
	        	//this.props.popupAction(false, this.props.pack, true);
	        break;
				default:
				break;
			}
		});
	}

	vk_pay_buy = (type) => {
        var self = this;
        var pack_id = (type == 1)?self.props.pack.id:0;
        axios.get(config.apiURL, {
            params: {
                a: 'getOrderId',
                type: type,
                pack_id: pack_id,
                vk_id: self.props.vkUser.id,
                vk_params: self.props.vkParams,
                vk_sign: self.props.vkSign,
            }
        }).then(function (response) {
        	if (!response.data.hasOwnProperty('error')) {
        		var order_id = response.data.order_id;
        		var amount = response.data.amount;
        		var ts = + new Date();
        		var merchant_data = base64.encode(JSON.stringify({"amount":amount,"currency":"RUB","order_id":order_id,"ts":ts}));
		        axios.get(config.apiURL, {
		            params: {
		                a: 'getVKpaySign',
		              	data: merchant_data,
		                vk_id: self.props.vkUser.id,
		                vk_params: self.props.vkParams,
		                vk_sign: self.props.vkSign,
		            }
		        }).then(function (response) {
		        	merchant_data = response.data.merchant_data;      
		          	var merchant_sign = response.data.merchant_sign;
		          	var description = (type == 1)?'Покупка набора '+self.props.pack.name:'Покупка всех наборов';
		          	var app_data = 'amount='+amount+'data={"currency":"RUB","merchant_data":"'+merchant_data+'","merchant_sign":"'+merchant_sign+'","order_id":"'+order_id+'","ts":'+ts+'}description='+description+'merchant_id='+config.merchantID+'version=2';
			        axios.get(config.apiURL, {
			            params: {
			                a: 'getVKpayAppSign',
			              	data: app_data,
			                vk_id: self.props.vkUser.id,
			                vk_params: self.props.vkParams,
			                vk_sign: self.props.vkSign,
			            }
			        }).then(function (response) {
				    	connect.send("VKWebAppOpenPayForm", {"app_id": 6945494, "action": "pay-to-service", "params": {
				    		"amount": amount,
				    		"description": description,
				  			"action": "pay-to-service",
				  			"merchant_id": config.merchantID,
				  			"version": 2,
				  			"sign": response.data.app_sign,
				  			"data": {
				  				"currency": "RUB",
				  				"merchant_data": merchant_data,
				  				"merchant_sign": merchant_sign,
				  				"order_id": order_id,
				  				"ts": ts,
				  			}
				    	}});
			        });
		        });
        	}
        });
	}

	buttonBuyPack = () => {
		this.vk_pay_buy(1);
	}

	buttonBuyUnlimited = () => {
		this.vk_pay_buy(2);
	}


	render() {
			return (
				<div className="popup-overlay">
			    <style dangerouslySetInnerHTML={{__html: `
			      .list-page, detail-age {
			      	overflow: hidden;
			      }
			    `}} />
				<div className="popup">
				<div className="popup_close" onClick={() => this.props.popupAction(false)}></div>
				<div className="popup_pack_image" style = {{backgroundImage: `url(${config.imageURL+this.props.pack.image})`}}></div>
				<p className="popup_pack_name">Категория<br />“{this.props.pack.name}”<br />не открыта!</p>
				<div className="popup_pack_button popup_pack_button-purple" onClick={this.buttonBuyPack}>Открыть набор<br />за <span>30</span> 15 руб.</div>
				{/*<div className="popup_pack_button popup_pack_button-transparent" onClick={this.buttonBuyUnlimited}>Открыть все наборы<br />за <span>1 000</span> 499 руб.</div>*/}
				</div>
				</div>
			);
		}
	}

	export default PopupPackBuy;