import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import axios from 'axios';
import {config} from '../config';
import Header from './Header';
import CardImage from './CardImage';
import PopupCardLearned from './PopupCardLearned';
import PopupPackLearned from './PopupPackLearned';

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.cardImageHandler = this.cardImageHandler.bind(this);
    this.popupCardLearnedHandler = this.popupCardLearnedHandler.bind(this);
    this.state = {
      card: null,
      pack: null,
      images: [],
      cardSelected: null,
      cardCorrect: null,
      cardLearned: null,
      packLearned: null,
      cardsTotal: 0,
      cardsLearnedTotal: 0,
      donotshow: this.props.donotshow,
    };
  }

  componentDidMount() {
    this.getCard();
  }

  getCard = () => {
    this.setState({cardSelected: null, cardCorrect: null, cardLearned: null, packLearned: null}, function() {
      var pack_id = this.props.match.params.packId;
      var self = this;
      axios.get(config.apiURL, {
          params: {
              a: 'get_card',
              pack_id: pack_id,
              vk_id: self.props.vkUser.id,
              vk_params: self.props.vkParams,
              vk_sign: self.props.vkSign,
          }
      }).then(function (response) {
          if (!response.data.hasOwnProperty('error')) {
            self.setState({
              card: response.data.card,
              pack: response.data.pack,
              images: self.shuffle(response.data.images),
            });
            if ('speechSynthesis' in window) {
              var synth = window.speechSynthesis;
              var utterThis = new SpeechSynthesisUtterance(response.data.card.word);
			  // произносит слова
             // synth.speak(utterThis);
            }
          }
      });
    });
  }

  shuffle = (a) => {
    {/*Функция для перемешивания массива*/}
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  cardImageHandler(index) {
    {/*Это функцию мы передаём в компонент CardImage, чтобы он мог сообщить о том что юзер нажал именно на эту картинку*/}
    if (this.state.cardSelected == null) {
      for (var i = 0; i < this.state.images.length; i++) {
        if (this.state.card.image == this.state.images[i].image) {
          var correct = i;
        }
      }
      this.setState({
        'cardSelected': index,
        'cardCorrect': correct,
      });
      if (index == correct) {
        {/*Если юзер выбрал правильную карточку, сообщаем об этом на сервер*/}
        var self = this;
        axios.get(config.apiURL, {
            params: {
                a: 'card_correct',
                card_id: self.state.card.id,
                vk_id: self.props.vkUser.id,
                vk_params: self.props.vkParams,
                vk_sign: self.props.vkSign,
            }
        }).then(function (response) {
            if (!response.data.hasOwnProperty('error')) {
              if (response.data.learned) {
                var pack = self.state.pack;
                pack['cards_learned']++;
                if (self.state.donotshow && pack['cards_learned'] < pack['cards_count']) {
                    setTimeout(function() {
                      self.getCard();
                    }, 1000);
                  } else {
                  document.getElementById("game").scrollTop = 0;
                  self.setState({cardLearned: (pack['cards_learned'] < pack['cards_count']), packLearned: (pack['cards_learned'] == pack['cards_count']), pack: pack, cardsLearnedTotal: response.data.cards_learned_total, cardsTotal: response.data.cards_total});
                }
              } else {    
                setTimeout(function() {
                  self.getCard();
                }, 1000);
              }
            }
        });
      } else {
        var self = this;
        setTimeout(function() {
          self.getCard();
          //self.props.history.push("/game/"+self.props.match.params.packId);
        }, 1000);
      }
    }
  }

  popupCardLearnedHandler(on, donotshow = false) {
    var self = this;
    self.getCard();
    if (donotshow) {
        var self = this;
        axios.get(config.apiURL, {
            params: {
                a: 'do_not_show',
                vk_id: self.props.vkUser.id,
                vk_params: self.props.vkParams,
                vk_sign: self.props.vkSign,
            }
        }).then(function (response) {
          self.state.donotshow = true;
        });
    }
  }

  render() {
    return (
      <div id="game" className="transition-item detail-page">
      	<Header notifications={this.props.notifications} vkUser={this.props.vkUser} game={true} pack={this.state.pack} />
        {this.state.card !== null &&
        <div className="game">
          <p className="game_word">{this.state.card.word}</p>
          <div className="game_cards">
           {this.state.images.map((image, i) => {
               return (<CardImage key={i} index={i} transparent={this.state.cardSelected !== null && i !== this.state.cardSelected && i !== this.state.cardCorrect} active={i == this.state.cardSelected} correct={i == this.state.cardCorrect} image={image.image} action={this.cardImageHandler} word_russian={image.word_russian} />) 
            })}
          </div>
        </div>
        }
        { this.state.cardLearned && !this.state.donotshow &&
          <PopupCardLearned pack={this.state.pack} popupAction={this.popupCardLearnedHandler} card={this.state.card} cardsTotal={this.state.cardsTotal} cardsLearnedTotal={this.state.cardsLearnedTotal} />
        }
        { this.state.packLearned  &&
          <PopupPackLearned pack={this.state.pack} popupAction={this.popupCardLearnedHandler} cardsTotal={this.state.cardsTotal} cardsLearnedTotal={this.state.cardsLearnedTotal} />
        }
      </div>
    );
  }
}

export default Game;