import React from 'react';
import axios from 'axios';
import {config} from '../config';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import connect from '@vkontakte/vkui-connect';
import '../Intro.css';

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registered: false,
			secondScreen: false,
        };
    }
  componentDidMount() {
    var self = this;
    axios.get(config.apiURL, {
        params: {
            a: 'register',
            vk_id: self.props.vkUser.id,
            vk_params: self.props.vkParams,
            vk_sign: self.props.vkSign,
        }
    }).then(function (response) {
        if (!response.data.hasOwnProperty('error')) {
           //self.props.action();
        }
    });
  }

	
	//второй экран
	toSecond = () => {
		this.setState({secondScreen: true});
	}
	
	//подписываемся и линяем в аппу
	toApp = () => {
		connect.send("VKWebAppAllowNotifications", {});
		this.props.action();
	}
	
	//кнопка продолжить
	ContinueToApp = () => {
		this.props.action();
	}
	
    render() {
		
		let screen;
		
		if(!this.state.secondScreen) screen = <div className="intro-first">
				<p className="lerning-english">Изучение<br />английского языка</p>
				<p className="lerning-words">Выучи тысячи английских слов.</p>
				<div className="button-intro" style={{marginTop: "40px"}} onClick={this.toSecond}>Начать изучение</div>
			</div>;
		else screen = <div className="intro-second">
				<p className="we-can-speak">Мы можем сообщать о новых и<br />бесплатных наборах карточек.</p>
				<div className="button-intro" style={{marginTop: "25px"}} onClick={this.toApp}>Ок</div>
				<div className="continue-app" onClick={this.ContinueToApp}>Продолжить</div>
			</div>;
		
		
        return (
          <div className="detail-page">
			{screen}
          </div>
          );
    }
}

export default Intro;